.prev,
.next {
    cursor: pointer;
    position: absolute;

    width: auto;
    margin-top: 25px;
    color: #fff !important;
    font-weight: bold;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

@media (min-width: 320px) {
    .prev,
    .next {
        margin-top: 155px;
    }
    .next-right {
        margin-left: 250px
    }
    .MuiButton-containedPrimary{
        font-size: 12px !important;
    }
  
}
@media (min-width: 360px) {
    .next-right {
        margin-left: 290px
    }
  
}
@media (min-width: 375px) {
    .next-right {
        margin-left: 310px
    }
    .MuiButton-containedPrimary{
        font-size: 14px !important;
    }
}
@media (min-width: 425px) {
    .next-right {
        margin-left: 360px
    }
}

@media (min-width: 768px) {

    .prev,
    .next {
        position: fixed;
        margin-top: 45px;
    }

    .next-right {
        margin-left: 475px
    }

    .totLaporan {
        padding-top: 10px;
        font-size: 16px;
    }
}

@media (min-width: 992px) {

    .prev,
    .next {
        position: fixed;
        margin-top: 45px;
    }

    .next-right {
        margin-left: 578px
    }

    .totLaporan {
        font-size: 19px;
    }
}

@media (min-width: 1200px) {

    .prev,
    .next {
        position: fixed;
        margin-top: 45px;
    }
    .next-right {
        margin-left: 695px
    }
}
.activeList{
    background-color: #bfbcbc;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
